import React from 'react';
import ReactDOM from 'react-dom/client';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
// import 'react-calendar/dist/Calendar.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import { combineReducers, compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import reducer from './redux/reducer';
import Idle from 'react-idle';
import { Provider } from 'react-redux';


const root = ReactDOM.createRoot(document.getElementById('root'));

const rootReducer = combineReducers({ globalState: reducer })

const composeEnhancers = (process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;
    
// const store = configureStore({ reducer: rootReducer, middleware:[thunk] });
const store = configureStore({ reducer: rootReducer });

root.render(<Idle timeout={12 * 60 * 60 * 1000} render={({ idle }) =>
    // <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter><App idle={idle} /></BrowserRouter>
        </Provider>
    // </React.StrictMode>
} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
