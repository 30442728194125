import React, { useState } from "react";
import { DCLButton } from "./OneDoseLibrary";
import { DCLEllipse } from "./Icons";
import DCLLogoText from "./LogoText";
import Logout from "../Utilities/Logout";
import {startCase} from 'lodash';
import { Dropdown } from "react-bootstrap";

const DCLHeader = props => { 

    const { signout } = Logout();   

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div ref={ref} onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }} >
          {children}
        </div>
      ));

    return <div className="dclheader-container">
        {/* <img style={logoStyle} src={Logo} alt='logo' /> */}
        <div className="dclheader-dctext"><DCLLogoText height='28' width='30' /></div>
        <div className="dclheader-button-container">
            {props.saveOnboarding && <DCLButton size='small' onClick={props.saveOnboarding}>Save</DCLButton>}
            {props.nameInitial && <>
                {props.group && <div className="dclheader-grouptext">{props.group}</div>}
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        <div className="dclheader-initial">
                            <span>{props.nameInitial}</span>
                            <span className="dclheader-ellipse"><DCLEllipse /></span>
                        </div></Dropdown.Toggle>
                    <Dropdown.Menu>
                        {props.userName && <Dropdown.Header>{startCase(props.userName)}</Dropdown.Header>}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => signout()}>Log out</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>}
        </div>
    </div>
}

export default DCLHeader