import React from "react"


const InputRow = props => {
   

    return <div className="dclinputrow">
        {props.title && <div className='rowtitle'>{props.title}</div>}
        {props.subtitle && <div className='rowsubtitle'>{props.subtitle}</div>}
        {props.children && <div  className={props.classradio ? "connectradiobtn" : "inputrow"}>
            {props.children}
        </div >}
    </div >
}

export default InputRow