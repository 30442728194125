import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { DCLTextInput, DCLButton, DCLWhiteBox, DCLInputRow } from "../OneDoseLibrary/OneDoseLibrary";
//import '../UserPages/account.scss';
//import wheelchair from './../Assets/wheelchair.png';
import DCLHeader from '../OneDoseLibrary/Header';
import { Modal } from 'react-bootstrap';
//import { ThumbsUpNurse } from '../OneDoseLibrary/Illustrations';

const SignIn = (props) => {
    const [alert, setAlert] = useState(true)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState('login')
    const [values, setValues] = useState({ email: '', password: '', verifycode: '', resetcode: '', newpass1: '', newpass2: '' })

    const setValue = (key, value) => {
        setValues({ ...values, [key]: value })
    }

    const pages = {
        login: {
            title: 'Login'
        },
        verify: {
            title: 'Verification Code',
            paragraph: 'A verification code has been sent to your email. The code is valid for 10 minutes.'
        },
        enteremail: {
            title: 'Forgot Password',
            paragraph: "Enter your email address. We'll send you a code to reset your password."
        },
        resetcode: {
            title: ' Enter code',
            paragraph: 'Enter the code that was sent to your email'
        },
        newpass: {
            title: 'Reset Password',
            paragraph: 'Reset your password below. Password must include: at least 8 characters, a capital letter, lowercase letter, special character and number.'
        },
        congrats: {
            title: 'Congrats',
            paragraph: 'You have successfully changed your password. You can now log in with your new password.'
        },
    }

    const TextBox = props => <DCLInputRow>
        <DCLTextInput id={props.id} key={props.id} i={props.i}
            onKeyUp={e => { if (e.key === 'Enter') { document.getElementById(props.btnId).click() } }}
            onChange={e => setValue(props.field, e.target.value)}
            fieldName={props.placeholder}
            type={props.type ? props.type : 'text'}
            value={values[props.field]} />
    </DCLInputRow>  

    const defaultErrorMessage = 'OOPS! Something went wrong. Contact support if error persists.'

    const signIn = () => {
        setLoading(true)
        axios.post('/api/login/', { email: values.email, password: values.password }, { headers: { 'X-CSRFToken': window.localStorage.getItem('dc_browser') } }).then(res => {
            if (res.status === 205) {
                setLoading(false)
                setPage('verify')
            }
            else {
                window.localStorage.setItem('dc_token', res.data);
                props.checkSession()
            }
        }).catch(err => {
            setLoading(false)
            if (err.response.status === 423) {
                setAlert('Too many invalid login attempts were made. Contact support to unlock your account.')
            } else if (err.response.status === 403) {
                setAlert(<>Your free trial has ended. Contact your admin to upgrade your account.</>)
            } else if (err.response.status === 401) {
                console.log("data",err.response.data);
                if(err.response.data.includes('User Inactive'))
                setAlert('Login Failed. Contact support if error persists')
                 else
                setAlert('Incorrect email or password')
            } else {
                setAlert(defaultErrorMessage)
            }
        })
    }

    useEffect(() => {
        setAlert('')
    }, [page])

    const forgotPassword = () => {
        setPage('enteremail')
    }

    const verify = () => {
        setLoading(true)
        axios.post('/api/registerbrowser/', { email: values.email, password: values.password, code: values.verifycode }).then(res => {
            console.log(res)
            try {
                res = JSON.parse(res.data)
                window.localStorage.setItem('dc_token', res.csrf);
                window.localStorage.setItem('dc_browser', res.code);
                props.checkSession()
                // setLoading(false)
            } catch (ex) {
                console.log(ex)
                setLoading(false)
            }
        }).catch(err => {
            setLoading(false)
            if (err.response.status === 423) {
                setAlert('You have tried too many invalid codes. Contact support to unlock you account.')
            } else if (err.response.status === 401) {
                setAlert('Invalid code')
            } else {
                setAlert(defaultErrorMessage)
            }
        })
    }

    const enterEmail = () => {
        setLoading(true)
        axios.post('/api/forgotpassword/', { email: values.email }).then(res => {
            setLoading(false)
            setPage('resetcode')
        }).catch(err => {
            setLoading(false)
            if (err.response.status === 411) {
                setAlert('Failed to send email.')
            } else {
                setAlert(defaultErrorMessage)
            }
        })
    }

    const resetCode = () => {
        setLoading(true)
        axios.post('/api/verify/', { email: values.email, code: values.resetcode }).then(res => {
            setLoading(false)
            setPage('newpass')
        }).catch(err => {
            setLoading(false)
            if (err.response.status === 423) {
                setAlert('You have tried too many invalid codes. Contact support to unlock you account.')
            } else if (err.response.status === 401 || err.response.status === 411) {
                setAlert('Invalid code.')
            } else {
                setAlert(defaultErrorMessage)
            }
        })
    }

    const newPass = () => {

        const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@\\/$%^&*-]).{8,}$/;
        const validity = pattern.test(values.newpass1)
        // validity ="" ?validity: 'Does not meet password requirements.';
        if (values.newpass1 !== values.newpass2) {
            setAlert('New passwords do not match.')
        } else if (!validity) {

            setAlert('Does not meet password requirements.')

        } else {
            setLoading(true)
            axios.post('/api/changepassword/', { email: values.email, code: values.resetcode, password: values.newpass1 }).then(res => {
                setLoading(false)
                setPage('congrats')
            }).catch(err => {
                setLoading(false)
                if (err.response.status === 406) {
                    setAlert('Please enter a password that you have not used in the past.')
                } else if (err.response.status === 401) {
                    setAlert('Invalid Code.')
                } else {
                    setAlert(defaultErrorMessage)
                }
            })
        }
    }

    const goBackPage = () => {
        setPage(page === 'resetcode' ? 'enteremail' : page === 'enteremail' ? 'login' : page)
    }


    return <div className='account'>
        <DCLHeader />
        <div className='textForm'>
            <div className='whiteboxside'>
                <div className='filler'></div>
                <DCLWhiteBox title={pages[page].title} subtitle={pages[page].paragraph} elementsAboveTitle={page === 'congrats' ? <div>thumbs up</div> : undefined}>

                    {alert && <div className='generalerror'>{alert}</div>}

                    {page === 'login' && <Fragment>
                        {TextBox({ id: 'dc_email', placeholder: 'Email', btnId: 'dc_signin', field: 'email' })}
                        {TextBox({ id: 'dc_password', placeholder: 'Password', btnId: 'dc_signin', field: 'password', type: 'password' })}
                        <a onClick={forgotPassword} id='dc_forgotlink' className='forgotlink pointer'>Forgot password</a>
                        <DCLButton loading={loading} id='dc_signin' value='LOGIN' onClick={signIn} size='stretch' />
                    </Fragment>}

                    {page === 'verify' && <Fragment>
                        {TextBox({ id: 'dc_code', placeholder: 'Verification Code', btnId: 'dc_btnverify', field: 'verifycode' })}
                        <DCLButton loading={loading} id='dc_btnverify' value='SUBMIT' onClick={verify} size='stretch' />
                    </Fragment>}

                    {page === 'enteremail' && <Fragment>
                        {TextBox({ id: 'dc_regemail', placeholder: 'Email', btnId: 'dc_btnregemail', field: 'email' })}
                        <DCLButton loading={loading} id='dc_btnregemail' value='SEND CODE' onClick={enterEmail} size='stretch' />
                    </Fragment>}

                    {page === 'resetcode' && <Fragment>
                        {TextBox({ id: 'dc_vercode', placeholder: 'Code', btnId: 'dc_btncode', field: 'resetcode' })}
                        <DCLButton loading={loading} id='dc_btncode' value='RESET PASSWORD' onClick={resetCode} size='stretch' />
                    </Fragment>}

                    {page === 'newpass' && <Fragment>
                        {TextBox({ i:1, id: 'dc_password1', placeholder: 'New Password', btnId: 'dc_btnnewpass', type: 'password', field: 'newpass1' })}
                        {TextBox({ i:2, id: 'dc_password2', placeholder: 'Confirm Password', btnId: 'dc_btnnewpass', type: 'password', field: 'newpass2' })}
                        <DCLButton loading={loading} id='dc_btnnewpass' value='RESET PASSWORD' onClick={newPass} size='stretch' />
                    </Fragment>}

                    {page === 'congrats' && <Fragment>
                        <DCLButton loading={loading} className='stretch' id='dc_rtrnlogin' value='RETURN TO LOGIN' onClick={() => setPage('login')} />
                    </Fragment>}
                </DCLWhiteBox>
                <div className='filler'></div>
                <div className='filler'></div>
            </div>
        </div></div>
}

export default SignIn