// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.navLinks{
display: flex;
width: 264px;
flex-direction: column;
align-items: flex-start;
flex-shrink: 0;
float: left;
border-right: 1px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
background: var(--Neutral-White, #FFF);
box-shadow: 4px 0px 30px 0px rgba(131, 98, 234, 0.05);
}

body{
text-align: center;
}
.logoContainer{
display: flex;
height: 64px;
padding: 8px 20px;
flex-direction:row;
justify-content: center;
align-items: flex-start;
gap: 10px;
align-self: stretch;
}

p{
    color: var(--Text-Primary, #000);
text-align: center;
font-family: Montserrat;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1.2px;
}

.frame1{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;
}
a{
    color:darkgrey
    
}`, "",{"version":3,"sources":["webpack://./src/Admin.css"],"names":[],"mappings":";AACA;AACA,aAAa;AACb,YAAY;AACZ,sBAAsB;AACtB,uBAAuB;AACvB,cAAc;AACd,WAAW;AACX,4DAA4D;AAC5D,sCAAsC;AACtC,qDAAqD;AACrD;;AAEA;AACA,kBAAkB;AAClB;AACA;AACA,aAAa;AACb,YAAY;AACZ,iBAAiB;AACjB,kBAAkB;AAClB,uBAAuB;AACvB,uBAAuB;AACvB,SAAS;AACT,mBAAmB;AACnB;;AAEA;IACI,gCAAgC;AACpC,kBAAkB;AAClB,uBAAuB;AACvB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;AACnB,qBAAqB;AACrB;;AAEA;IACI,aAAa;AACjB,sBAAsB;AACtB,uBAAuB;AACvB,QAAQ;AACR,mBAAmB;AACnB;AACA;IACI;;AAEJ","sourcesContent":["\n.navLinks{\ndisplay: flex;\nwidth: 264px;\nflex-direction: column;\nalign-items: flex-start;\nflex-shrink: 0;\nfloat: left;\nborder-right: 1px solid var(--Neutral-Gray-Gray-50, #F0F1F3);\nbackground: var(--Neutral-White, #FFF);\nbox-shadow: 4px 0px 30px 0px rgba(131, 98, 234, 0.05);\n}\n\nbody{\ntext-align: center;\n}\n.logoContainer{\ndisplay: flex;\nheight: 64px;\npadding: 8px 20px;\nflex-direction:row;\njustify-content: center;\nalign-items: flex-start;\ngap: 10px;\nalign-self: stretch;\n}\n\np{\n    color: var(--Text-Primary, #000);\ntext-align: center;\nfont-family: Montserrat;\nfont-size: 24px;\nfont-style: normal;\nfont-weight: 700;\nline-height: normal;\nletter-spacing: 1.2px;\n}\n\n.frame1{\n    display: flex;\nflex-direction: column;\nalign-items: flex-start;\ngap: 8px;\nalign-self: stretch;\n}\na{\n    color:darkgrey\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
