import React from "react"
import isNullorEmpty from "../Utilities/isNullorEmpty"
import { capitalize } from 'lodash'
import { DCLWarning } from "./Icons"


export const TextInput = props => {
    const changePlaceHolder = (placeholderProps) => {
            const regex = /\bid\b/gi;
            placeholderProps= placeholderProps.replace(regex, 'ID');
        return placeholderProps
    }
    return <> {!props.hide && <div className="dcltextinput">
        <input
            autoComplete={props.autoComplete}
            className={props.errMsg ? 'err' : ''}
            id={props.fieldName.replaceAll(' ', '_') + (props.i ? props.i : '')}
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur ? e => { props.onBlur(); e.relatedTarget && e.relatedTarget.click() } : undefined}
            onKeyUp={props.onKeyUp}
            type={props.type ? props.type : 'text'}
            placeholder={changePlaceHolder(props.placeholder_recommended ? capitalize(props.fieldName) + props.placeholder_recommended : capitalize(props.fieldName))} />
        {!isNullorEmpty(props.value) && props.fieldName && !props.hideTitle && <div className='labelwrapper'>
            <label className={props.errMsg ? 'err' : ''} for={props.fieldName.replaceAll(' ', '_')}>{props.fieldName}</label>
        </div>}
        {props.type == 'password' && !props.showeye && <Eye i={props.i ? props.i : ''} txtId={props.fieldName.replaceAll(' ', '_')} />}
        {props.errMsg && <div className='errmsg' fieldId={props.fieldName.replaceAll(' ', '_')} >
            <DCLWarning />{props.errMsg}
        </div>}
    </div >}</>

}
export default TextInput

export const NestedTextInput = props => {
    const updatedProps = {
        ...props,
        value: props.value.value === undefined ? '' : props.value.value
    }
    if (!isNullorEmpty(props.value.errMsg)) {
        updatedProps.errMsg = props.value.errMsg
    }
    return <TextInput {...updatedProps} />
}

const Eye = props => <div className="eye" onClick={() => {
    document.getElementById(props.txtId + props.i).type = document.getElementById(`dc_astext${props.i}`).classList.contains('hide') ? 'password' : 'text';
    hideClass.toggle('dc_astext' + props.i)
    hideClass.toggle('dc_aspassword' + props.i)
}}>
    <svg id={`dc_astext${props.i}`} className="closed" viewBox="0 0 22 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1C1.85632 2.02759 3.05803 3.16077 4.5 4.11472M20.5 1C19.1849 2.66117 17.8409 3.86722 16.5 4.70519M16.5 4.70519L19.5 8M16.5 4.70519C14.4546 5.98346 12.4166 6.40521 10.5 6.27943M10.5 6.27943V10.5M10.5 6.27943C8.27743 6.13357 6.21816 5.25138 4.5 4.11472M4.5 4.11472L2 7.5" stroke="#71706E" strokeWidth="2" />
    </svg>
    <svg id={`dc_aspassword${props.i}`} className='open hide' viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 5.79481C4.65914 6.63277 3.3151 7.83882 2 9.5C3.33333 11.3333 7.2 15 12 15C16.8 15 20.3333 11.3333 21.5 9.5C20.6437 8.47241 19.442 7.33923 18 6.38528M6 5.79481L3 2.5M6 5.79481C8.04541 4.51654 10.0834 4.09478 12 4.22057M12 4.22057L12 -8.30516e-07M12 4.22057C14.2226 4.36643 16.2818 5.24862 18 6.38528M18 6.38528L20.5 3" stroke="#71706E" strokeWidth="2" />
        <circle cx="11.5" cy="9.5" r="3" transform="rotate(-180 11.5 9.5)" fill="#71706E" />
    </svg>

</div>


const hideClass = {
    remove: id => document.getElementById(id).classList.remove('hide'),
    add: id => document.getElementById(id).classList.add('hide'),
    toggle: id => document.getElementById(id).classList.toggle('hide')
}