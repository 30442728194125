import React, {useEffect, useState} from 'react';
import { Route, Routes, Outlet } from 'react-router';
import { Link } from "react-router-dom";
import axios from "axios";
import oneDoseLogo from "./Logo/logo.png"
import PreregisterUsers from "./AdminPages/PreregisterUsers"
import Users from "./AdminPages/Users"
import Invites from "./AdminPages/Invites"
import BlackList from "./AdminPages/BlackList"
import BackendCreds from "./AdminPages/BackendCreds"
import UserActivity from "./AdminPages/UserActivity"
import BillingInfo from "./AdminPages/BillingInfo"
import Groups from "./AdminPages/Groups"
import CustomerServices from "./AdminPages/CustomerServices"
import ReleaseNotes from "./AdminPages/ReleaseNotes"
import './Admin.css'


const Admin = () => {
    const [groups, setGroups] = useState(null)

    useEffect(() => {      
        getGroups() 
        // getFacilities()
    }, [])


    const getGroups = () => {
        axios.get('/api/internal/getgroups/').then((res) => {
            setGroups(res.data)
        }).catch(err => {
            console.log(err);
        }) 
    }

    return  <div className='client-loading'>
            <nav className="navLinks">
                <div className='logoContainer'>
                    <img id="logo" src={oneDoseLogo} />
                    <p>OneDose</p>
                </div>
                <div className='menu'>
                    <menu className='mainMenu'>
                        <frameElement className='frame1'>
                        <button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown">ADMIN</button>
                            <Link to="/admin/users">Users</Link>
                            <Link to="/admin/invites">Invites</Link>
                            <Link to="/admin/addusers">Addusers</Link>
                            <Link to="/admin/blacklist">Blacklist</Link>
                            <Link to="/admin/activity">Activity</Link>
                            <Link to="/admin/releasenotes">Releasenotes</Link>
                            <Link to="/admin/billing">Billing</Link>
                            <Link to="/admin/groups">Groups</Link>
                            <Link to="/admin/services">Services</Link>
                        </frameElement>
                    </menu>
                </div>
            </nav>

            <div className='main' >
            <Routes>
                <Route path='addusers' element={<PreregisterUsers groups={groups} />} />
                <Route path='users' element={<Users groups={groups}/>} />
                <Route path='invites' element={<Invites />} />
                <Route path='blacklist' element={<BlackList />} />
                <Route path='backend' element={<BackendCreds />} />
                <Route path='activity' element={<UserActivity groups={groups}/>} />
                <Route path='billing' element={<BillingInfo groups={groups} getGroups={getGroups} />} />
                <Route path='groups' element={<Groups groups={groups} getGroups={getGroups} />} />
                <Route path='services' element={<CustomerServices/>} />
                <Route path='releasenotes' element={<ReleaseNotes />} />
            </Routes>
        </div>        
    </div>
}

export default Admin;
