import axios from "axios"
import Config from "../Utilities/Config"
import * as actions from './actions'
import startCase from 'lodash';


export const loadActiveFacilities = () => {
    return dispatch => {
        axios.get('/api/facilities/?activeOnly', Config()).then((res) => {
            dispatch({ type: actions.UPDATE, field: 'activeFacilities', activeFacilities: res.data });
        }).catch(err => {
            console.log(err.response);
        });
        // axios.get(`/api/facilities/${true}/`, Config()).then(res => {
        //     //Checking if group is a parent group - if yes, adding group name to facility name: 
        //     let fac = res.data
        //     const unique = [...new Set(res.data.map(f => f.group))]
        //     if (unique.length > 1){                
        //         fac = res.data.map(f => {return {...f, 'facility_name' : (startCase(f.group) + " - " + f.facility_name)}})
        //     }
        //     dispatch({ type: actions.UPDATE, field: 'facilities', facilities: fac });
        // }).catch(err => {
        //     console.log(err.response);
        // });
    }
};

export const loadAllFacilities = () => {
    return (dispatch, getState) => {
        axios.get('/api/facilities/', Config()).then(res => {
            //Checking if group is a parent group - if yes, adding group name to facility name: 
            const groups = [...new Set(res.data.map(f => f.group))]
            let fac = res.data
            if (groups.length > 1){                
                fac = fac.map(f => {return {...f, 'facility_name' : (startCase(f.group) + " - " + f.facility_name)}})
            }
            dispatch({ type: actions.UPDATE, field: 'allFacilities', allFacilities: fac });
        }).catch(err => {
            console.log(err.response);
        });
    }
};

export const getGroupBillingInfo = () => {
    return (dispatch, getState) => {
        // debugger
        axios.get(`/api/groupbilling/`, Config()).then((res) => {
            console.log('getGroupBillingInfo func ran. res: ',res);
            dispatch({ type: actions.UPDATE, field: 'currentGroup', currentGroup: res.data })
        })
        .catch(err => {
            console.log(err)
            dispatch({ type: actions.UPDATE, field: 'currentGroup', currentGroup: 'error' })
        });
    }
}


export const setIsChildGroup = value => dispatch => dispatch({ type: actions.UPDATE, field: 'isChildGroup', isChildGroup: value })
export const setCurrentGroup = group => dispatch => dispatch({ type: actions.UPDATE, field: 'currentGroup', currentGroup: group })

export const setUser = user => dispatch => dispatch({ type: actions.UPDATE, field: 'user', user: user })
export const setLoading = isLoading => dispatch => dispatch({ type: actions.UPDATE, field: 'isLoading', isLoading: isLoading })

export const addToIVFluids = (ivfluids, facility) => {
    return (dispatch, getState) => {
        const existingList =getState().globalState.ivfluids;
        const notInList = ivfluids.filter(i => !existingList.find(ii=>ii.id===i.id))
        const combined = existingList.concat(notInList.map(i => ({ ...i, facilityid: facility })))
        dispatch({ type: actions.UPDATE, field: 'ivfluids', ivfluids: combined })
    }
}
export const addDocToIVFluids = (id, urls, docNames) => {
    return (dispatch, getState) => {
        const existingList =getState().globalState.ivfluids;
        const addDocs = existingList.map(i => i.id === id ? ({...i, in_subset_names: docNames, 'urls':urls }) : {...i})
        dispatch({ type: actions.UPDATE, field: 'ivfluids', ivfluids: addDocs })
    }
}

export const resetState = () => dispatch => dispatch({ type: actions.LOGOUT})
 