import React from "react";
import Button from './Button'
import TextInput, { NestedTextInput } from './TextInput'
import Box from './WhiteBox'
import InputRow from "./InputRow";


export const DCLButton = Button
export const DCLTextInput = TextInput
export const DCLNestedTextInput = NestedTextInput
export const DCLWhiteBox = Box
export const DCLInputRow = InputRow
