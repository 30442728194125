import React, { Fragment, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { Navigate, useHistory, useSearchParams } from 'react-router-dom'
import SignIn from './UserInterface/SignIn';
//import IVList from './UserInterface/IVList';
//import UserInterface from './UserInterface/UserInterface';
import axios from 'axios';
import Config from './Utilities/Config';
//import Spinner from './Utilities/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllFacilities, setUser, setLoading, loadActiveFacilities } from './redux/globalfunctions';
//import Logout from './Utilities/Logout';
//import isNullorEmpty from './Utilities/isNullorEmpty';
//import Onboarding from './OnboardingDashboard/Onboarding';
//import LoadingPage from './DolphinCareLibrary/LoadingPage';


const Client = (props) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate();
    const routerLocation = useLocation();
    const dispatch = useDispatch()
    const user = useSelector(state => state.globalState.user)
    //const loading = useSelector(state => state.globalState.isLoading)
    //const { signout } = Logout()
    //const [billing, setBilling] = useState(false)
    //const [noActiveFacs, setNoActiveFacs] = useState(null)
    //const [childGroups, setChildGroups] = useState(null)
    //const [UserGroupStatus, setUserGroupStatus] = useState(null)

    useEffect(() => {
        dispatch(setLoading(true))

        checkSession()
    }, [])

    useEffect(() => {
        //if (props.idle) { signout() }
    }, [props.idle])

    // useEffect(() => {
    //     if (user && ['Trial Ended', 'Billing Error'].includes(user.billing_status) && routerLocation.pathname !== '/client/billing') {
    //         navigate('/client/billing')
    //     }
    // }, [routerLocation])

    const checkSession = () => {
        if (routerLocation.pathname.includes('/onboarding/') && searchParams.get('token')) {
            return
        }
        axios.post('/api/checkdashboardsession/', {}, Config()).then(res => {
            dispatch(setUser(res.data))
            if (((res.data.billing_status === 'Onboarding') || res.status === 204) && !routerLocation.pathname.includes('onboarding')) {
                navigate('/client/onboarding')
            }
            else if ((res.data.billing_status === 'Registered') && routerLocation.pathname !== '/client/onboarding/complete') {
                navigate('/client/onboarding/complete')
            }
            else if (res.data.billing_status === 'Trialing' && routerLocation.pathname.includes('onboarding')) {
                navigate('/client')
            }
            else if (['Trial Ended', 'Billing Error'].includes(res.data.billing_status) && routerLocation.pathname !== '/client/billing') {
                navigate('/client/billing')
                dispatch(setLoading(false))
            }

            else {
                dispatch(loadAllFacilities())
                dispatch(loadActiveFacilities())
                if (routerLocation.pathname == '/client/signin') { navigate('/client') }
                dispatch(setLoading(false))
            }
        }).catch(err => {
            console.log(err)
            if (searchParams.get('token') && routerLocation.pathname.includes('onboarding')) {
                navigate('/client/onboarding')
            } else {
                dispatch(setLoading(false))
                dispatch(setUser(null))
                navigate('/client/signin')
            }
        })
    }

    // const getGroupsBilling = (user) => {
    //     axios.get(`/api/groupbillingdisplaystatus/${user.group}/`, Config()).then((res) => {
    //         setBilling(res.data)
    //         dispatch(setLoading(false))
    //     }).catch((err) => {
    //         dispatch(setLoading(false))
    //     })
    // }

    // const getGroupStatus = (user) => {
    //     axios.get(`/api/groupstatus/${user.group}/`, Config()).then((res) => {
    //         setUserGroupStatus(res.data)
    //         dispatch(setLoading(false))
    //     }).catch((err) => {
    //         dispatch(setLoading(false))
    //     })
    // }


    return <>
        {!user ?
            <Routes>
                <Route path='/' element={<Navigate to='signin' replace={true} />} />
                <Route path='signin' element={<SignIn checkSession={checkSession} />} />
                {/* {searchParams.get('token') && <Route path='/onboarding/*' element={<Onboarding />} />} */}
            </Routes> : <div>You are logged in!</div>
            // : (user.billing_status === 'Onboarding' || user.billing_status === 'Registered') ?
            //     <Routes>
            //         <Route path='/onboarding/*' element={<Onboarding />} />
            //     </Routes>
            //     : <UserInterface billing={user.billing_status} noActiveFacs={noActiveFacs} childGroups={childGroups} />
            }
    </>
};

export default Client;