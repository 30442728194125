import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import Config from "../Utilities/Config";
import { getPdfUrl } from "../Utilities/getPdfUrl";
import Spinner from "../Utilities/Spinner";
import './Document.css';


const Document = () => {
    const routerParams = useParams()
    const [searchParams] = useSearchParams()
    const [url, setUrl] = useState(null)
    const [err, setErr] = useState(null)
    const [fullPage, setFullPage] = useState(null)
    
    useEffect(() => {
        getDoc()
        // const interval = setInterval(() => {
        //     if (!url && localStorage.getItem('dc_token')) {
        //         getDoc()
        //         clearInterval(interval)
        //     }
        // }, 500);
    }, [])

    const getDoc = () => {
        axios.get(`/api/document/${routerParams.type}/${routerParams.id}/`, Config()).then(res => {
            if (res.data === '') {
                setErr('Failed to load Document.')
            }
            else if (routerParams.type === 'connect') {
                setUrl(res.data)
            } else {
                const url = getPdfUrl(res.data)
                setUrl(url)
            }
        }).catch(err => {
            if (err.response.status === 401) {
                setErr('You do not have permission to view this document')
            } else {
                setErr('Failed to load Document.')
            }
        })

        // axios.get(`/${namespace}/document/fast/${routerParams.type}/${routerParams.id}/`, Config()).then(res => {
        //     console.log(res.data)
        //     // if (res.data === '') {
        //     //     setErr('Failed to load Document.')
        //     // }
        //     // else if (routerParams.type === 'connect') {
        //     //     setUrl(res.data)
        //     // } else {
        //     //     setUrl(getPdfUrl(res.data))
        //     // }
        // }).catch(err => {
        //     console.log(err.response)
        //     // if (err.response.status === 401) {
        //     //     setErr('You do not have permission to view this document')
        //     // } else {
        //     //     setErr('Failed to load Document.')
        //     // }
        // })
    }
    useEffect(() => {
        const nav = document.getElementsByClassName('navigation')
        if (nav.length > 0) {
            try {
                console.log(document.getElementsByTagName('body')[0].offsetHeight)
                nav[0].style.height = document.getElementsByTagName('body')[0].offsetHeight - 24 + 'px'
            } catch {
                nav[0].height = '100vh'
            }
        }
    })

    const getDocViewerParams = () => {
        // console.log(searchParams.get('fullpage'),searchParams.get('page'))

        const paramArray = []
        if (searchParams.get('fullpage') !== 'true' || routerParams.type === 'connect') paramArray.push('toolbar=0')
        if (searchParams.get('page')) paramArray.push('page=' + searchParams.get('page'))
        return `#${paramArray.join('&')}`
    }



    // return <div className={"document" + (routerParams.type !== 'connect' || !url ? ' h' : '')}>
    return <div className={'full-height'}>
        {!url && !err ? <Spinner /> : url ?
            routerParams.type === 'connect' ? <div dangerouslySetInnerHTML={{ __html: url.replaceAll('&nbsp;', '\u00A0') }}></div> :
                <iframe height="100%" width="100%" src={url + getDocViewerParams()} allowFullScreen></iframe> :
            <p>{err}</p>}
    </div>
}


export default Document;