import React, { useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import axios from 'axios';
// import isNullorEmpty from '../Utilities/isNullorEmpty';
import Config from '../Utilities/Config';
// import { formatDateTime, formatDate } from '../Utilities/FormatDate';
// import dynamicSort from '../Utilities/SortTable';
// import { Outlet, Link } from "react-router-dom";
// import GroupFilter from './GroupFilter';
import { capitalize } from 'lodash';
// import Papa, { parse } from 'papaparse';
// import ReactTooltip from 'react-tooltip';
// import UserPopup from './UserPopup';
// import { setCurrentGroup } from '../redux/globalfunctions';


const Users = (props) => {
    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState(null)
    const [currentUsers, setCurrentUsers] = useState(users)
    const [error, setError] = useState(null)

    useEffect(() => {
        SelectUsers()
    }, [])


    const SelectUsers = () => {
        setLoading(true)
        axios.get('/api/admin/getusers/', Config()).then((res) => {
            setUsers(res.data)
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setError(`Couldn't get all users for ${err.response.data}`)
        })
    }

    return <div className='usersInfo'>  
    {loading ? <Spinner animation='border' variant='dark'/> :
        error ? <div>{error}</div> :
            users && <div>
                <Form className='wider'>
                    <table>
                        {currentUsers}
                        {/* {currentUsers && 
                        (<tr>
                            there are current users
                        </tr>)
                        } */}
                    </table>
                    </Form>
        </div>
    }
</div>
}

export default Users