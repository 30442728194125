import React from "react";
import { DCLLogo } from "./Icons"

const textStyle = {    
    color: '#000',
    fontSize: '16px',
    fontWeight: '700',
    letterSpacing: '0.8px'
}

const logoStyle = {
    marginRight: '8px'
}

const DCLLogoText = (props) => {
    return <div>
        <span style={logoStyle}><DCLLogo height={props.height ? props.height : undefined} width={props.width ? props.width : undefined} /></span>
        <span style={textStyle}>DolphinCare</span>
    </div>
}

export default DCLLogoText;